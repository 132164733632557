











































































import {
  DtoUserForget,
  forgetPass,
  reqValidateCode,
  FormRules,
  forgetSmsSend
} from "@/api";
import { Component, Vue } from "vue-property-decorator";
import { Form } from "element-ui";
interface CurrForm extends DtoUserForget, Record<string, string> {}
@Component({
  name: "forget"
})
export default class extends Vue {
  imgsrc = "";

  form: CurrForm = {
    emailValidateCode: "",
    password: "",
    passwordAgain: "",
    userName: "",
    validateCode: "",
    phoneValidateCode: ""
  };

  get rules(): FormRules<CurrForm> {
    return {
      userName: [
        {
          required: true,
          message: "请输入用户名",
          trigger: "blur"
        }
      ],
      password: [
        {
          required: true,
          message: "请输入密码",
          trigger: "blur"
        }
      ],
      passwordAgain: [
        {
          required: true,
          message: "请再次输入密码",
          trigger: "blur"
        },
        {
          message: "两次密码不一致",
          validator: (rule, value, callback) => {
            if (value !== this.form.password) {
              callback(new Error("两次密码不一致"));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }
      ],
      validateCode: [
        {
          required: true,
          message: "请输入图形验证码",
          trigger: "blur"
        }
      ],
      phoneValidateCode: [
        {
          required: true,
          message: "请输入短信验证码",
          trigger: "blur"
        }
      ]
      // emailValidateCode: [
      //   {
      //     required: true,
      //     message: "请输入邮箱验证码",
      //     trigger: "blur"
      //   }
      // ]
    };
  }

  loging = false;
  async findHandler() {
    try {
      this.loging = true;
      await (this.$refs.form as Form).validate();
      await forgetPass(this.form);
      this.$message.success("修改成功");
      this.$router.go(-1);
    } catch (error) {
      const { response } = error;
      this.$message.error(response.data.message);
    } finally {
      this.loging = false;
    }
  }

  validCodeRequesting = false;
  async initValidCode() {
    try {
      this.validCodeRequesting = true;
      const { data } = await reqValidateCode();
      this.imgsrc = data;
    } catch (error) {
      //
    } finally {
      this.validCodeRequesting = false;
    }
  }

  canSendCode = true;
  sendCodeTimeInterval: any;
  second = 60;
  buttonName = "发送验证码";
  async sendSmsCode() {
    if (!this.canSendCode) return;
    if (!this.form.validateCode)
      return this.$message.error("请先输入图形验证码");
    const data = {
      userName: this.form.userName,
      validateCode: this.form.validateCode
    };
    try {
      await forgetSmsSend(data);
      this.canSendCode = false;
      this.sendCodeTimeInterval = setInterval(() => {
        this.buttonName = `${this.second}秒后重新发送`;
        --this.second;
        if (this.second < 0) {
          this.buttonName = "重新发送";
          this.second = 60;
          this.canSendCode = true;
          window.clearInterval(this.sendCodeTimeInterval);
          this.sendCodeTimeInterval = null;
        }
      }, 1000);
      this.$message.success("发送成功");
    } catch (error) {
      this.canSendCode = true;
      this.$message.error("发送失败");
    }
  }

  beforeDestroy(): void {
    window.clearInterval(this.sendCodeTimeInterval);
    this.sendCodeTimeInterval = null;
  }

  created() {
    this.initValidCode();
    if (this.$route.params.name != "null") {
      this.form.userName = this.$route.params.name;
    }
  }
}
